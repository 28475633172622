import React from "react";
import "../App.css"
import logo from "../assets/logo1.png"

export default function Navigation(){

    const scroll=()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }
    return(
        <nav>
        <a href="#home"  id="scroll" onClick={scroll}><img src={logo} alt="logo" width="70px" /></a>
        <ul>
            <li>
                <a href="#features" >Iespējas</a>
            </li>
            <li>
                <a href="#pricing">Cenas</a>
            </li>
        </ul>
    </nav>
    )
}