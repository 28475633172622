
import './App.css';
import Navigation from './components/nav';
import Home from './components/home';
import Features from './components/features';
import Item from './components/item';
import Pricing from './components/pricing';
import Footer from './components/footer';

function App() {
  return (
<>
<Navigation/>
<Home/>
<Features/>
<Item/>
<Pricing/>
<Footer/>
</>
  );
}

export default App;
